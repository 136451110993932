body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0; padding: 0; box-sizing: border-box;
}

h1 {
  text-align: center;
}

.cell {
  animation: fadein 600ms;
  transition: fill 400ms ease-in-out;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.tooltip {
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
}

text {
  dominant-baseline: central;
  text-anchor: middle;
  font-size: 12px;
}

.axis-tick {
  color: #999;
  font-size: 12px;
  position: absolute;
  text-align: center;
  width: 100px;
  margin-left: -50px;
}
.axis-tick::before {
  position: absolute;
  width: 1px;
  height: 5px;
  background: #666;
  top: -3px; 
  left: 50px;
  content: ''
}
.axis-tick:last-of-type::before {
  left: 49px;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}